<template>
  <div class="body">
    <h1>403</h1>
    <div>
      <p>> <span>ERROR CODE</span>: "<i>HTTP 403 Forbidden</i>"</p>
      <p>
        > <span>ERROR DESCRIPTION</span>: "<i
          >Access Denied. You Do Not Have The Permission To Access This Page On
          This Server</i
        >"
      </p>
      <p>
        > <span>ERROR POSSIBLY CAUSED BY</span>: [<b
          >execute access forbidden, read access forbidden, write access
          forbidden, ssl required, ssl 128 required, ip address rejected, client
          certificate required, site access denied, too many users, invalid
          configuration, password change, mapper denied access, client
          certificate revoked, directory listing denied, client access licenses
          exceeded, client certificate is untrusted or invalid, client
          certificate has expired or is not yet valid, passport logon failed,
          source access denied, infinite depth is denied, too many requests from
          the same client ip</b
        >...]
      </p>
      <p>
        > <span>GO BACK TO PREVIOUS PAGE</span>:
        <v-btn text color="light-blue" @click="$router.push('/')">
          Back Home</v-btn
        >
      </p>
    </div>
    <a class="avatar" title="current user" @click="back()"
      ><img :src="user.avatar"
    /></a>
  </div>
</template>

<script>
export default {
  props: ["user"],
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Share+Tech+Mono|Montserrat:700");

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  color: inherit;
}

.body {
  background-image: linear-gradient(120deg, #13729e 0%, #000000 100%);
  height: 100vh;
  width: 100%;
  position: fixed;
}

h1 {
  font-size: 45vw;
  text-align: center;
  position: fixed;
  width: 100vw;
  z-index: 1;
  color: #ffffff26;
  text-shadow: 0 0 50px rgba(0, 0, 0, 0.07);
  top: 50%;
  transform: translateY(-50%);
  font-family: "Montserrat", monospace;
}

div {
  background: rgba(0, 0, 0, 0);
  width: 70vw;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 30px 30px 10px;
  box-shadow: 0 0 150px -20px rgba(0, 0, 0, 0.5);
  z-index: 3;
}

P {
  font-family: "Share Tech Mono", monospace;
  color: #f5f5f5;
  margin: 0 0 20px;
  font-size: 17px;
  line-height: 1.2;
}

span {
  color: #bb9344;
}

i {
  color: #8abeb7;
}

div a {
  text-decoration: none;
}

b {
  color: #81a2be;
}

a.avatar {
  position: fixed;
  bottom: 15px;
  right: -100px;
  animation: slide 0.5s 4.5s forwards;
  display: block;
  z-index: 4;
}

a.avatar img {
  border-radius: 100%;
  width: 44px;
  border: 2px solid rgb(255, 255, 255);
}

@keyframes slide {
  from {
    right: -100px;
    transform: rotate(360deg);
    opacity: 0;
  }
  to {
    right: 15px;
    transform: rotate(0deg);
    opacity: 1;
  }
}
</style>
